import React from "react";
import { graphql } from "gatsby";
// import * as pageBuilderComponents from 'components/PageBuilderComponents'
import loadable from "@loadable/component";
console.error("The config object was not found.");
const componentNamePattern = "Craft_PageBuilder";

const AppShowcase = loadable(() =>
  import("../PageBuilderComponents/AppShowcase")
);
const Countdown = loadable(() => import("../PageBuilderComponents/Countdown"));
const Faq = loadable(() => import("../PageBuilderComponents/Faq"));
const Form = loadable(() => import("../PageBuilderComponents/Form"));
const Gallery = loadable(() => import("../PageBuilderComponents/Gallery"));
const Hero = loadable(() => import("../PageBuilderComponents/Hero"));
const HomeHero = loadable(() => import("../PageBuilderComponents/HomeHero"));
const Hr = loadable(() => import("../PageBuilderComponents/Hr"));
const Html = loadable(() => import("../PageBuilderComponents/Html"));
const Image = loadable(() => import("../PageBuilderComponents/Image"));
const ImageText = loadable(() => import("../PageBuilderComponents/ImageText"));
const Instagram = loadable(() => import("../PageBuilderComponents/Instagram"));
const List = loadable(() => import("../PageBuilderComponents/List"));
const Logos = loadable(() => import("../PageBuilderComponents/Logos"));
const MembershipSpecial = loadable(() =>
  import("../PageBuilderComponents/MembershipSpecial")
);
const Newsletter = loadable(() =>
  import("../PageBuilderComponents/Newsletter")
);
const Slider = loadable(() => import("../PageBuilderComponents/Slider"));
const Testimonials = loadable(() =>
  import("../PageBuilderComponents/Testimonials")
);
const Text = loadable(() => import("../PageBuilderComponents/Text"));
const Timetable = loadable(() => import("../PageBuilderComponents/Timetable"));
const Trainers = loadable(() => import("../PageBuilderComponents/Trainers"));
const Video = loadable(() => import("../PageBuilderComponents/Video"));
const Videos = loadable(() => import("../PageBuilderComponents/Videos"));

const components = {
  AppShowcase,
  Countdown,
  Faq,
  Form,
  Gallery,
  Hero,
  HomeHero,
  Hr,
  Html,
  Image,
  ImageText,
  Instagram,
  List,
  Logos,
  MembershipSpecial,
  Newsletter,
  Slider,
  Testimonials,
  Text,
  Timetable,
  Trainers,
  Video,
  Videos,
};

function getComponent(__typename) {
  const componentName = __typename.replace(componentNamePattern, "");
  const Component = components[componentName];
  return Component || false;
}

export default function PageBuilder({ moduleSlug, pageBuilder, pageContext }) {
  return pageBuilder && pageBuilder.length > 0
    ? pageBuilder.map((el, i) => {
        const PageBuilderComponent = getComponent(el.__typename);
        const key = `${moduleSlug}-${el.id}-${i}`;
        return PageBuilderComponent ? (
          <PageBuilderComponent
            pageBuilder={el}
            key={key}
            pageContext={pageContext}
          />
        ) : null;
      })
    : null;
}

export const query = graphql`
  fragment PageBuilderQuery on Craft_PageBuilderUnion {
    __typename

    ... on Craft_PageBuilderText {
      ...PageBuilderTextQuery
    }

    ... on Craft_PageBuilderImage {
      ...PageBuilderImageQuery
    }

    ... on Craft_PageBuilderImageText {
      ...PageBuilderImageTextQuery
    }

    ... on Craft_PageBuilderSlider {
      ...PageBuilderSliderQuery
    }

    ... on Craft_PageBuilderHero {
      ...PageBuilderHeroQuery
    }

    ... on Craft_PageBuilderTimetable {
      ...PageBuilderTimetableQuery
    }

    ... on Craft_PageBuilderList {
      ...PageBuilderListQuery
    }

    ...TrainersQuery

    ... on Craft_PageBuilderInstagram {
      ...PageBuilderInstagramQuery
    }

    ... on Craft_PageBuilderForm {
      ...PageBuilderFormQuery
    }

    ... on Craft_PageBuilderVideo {
      ...PageBuilderVideoQuery
    }

    ... on Craft_PageBuilderLogos {
      ...PageBuilderLogosQuery
    }

    ... on Craft_PageBuilderCountdown {
      ...PageBuilderCountdownQuery
    }

    ... on Craft_PageBuilderHr {
      ...PageBuilderHrQuery
    }

    ... on Craft_PageBuilderTestimonials {
      ...PageBuilderTestimonialsQuery
    }

    ... on Craft_PageBuilderFaq {
      ...PageBuilderFaqQuery
    }

    ... on Craft_PageBuilderGallery {
      ...PageBuilderGalleryQuery
    }

    ... on Craft_PageBuilderHomeHero {
      ...PageBuilderHomeHeroQuery
    }

    ... on Craft_PageBuilderNewsletter {
      ...PageBuilderNewsletterQuery
    }

    ... on Craft_PageBuilderVideos {
      ...PageBuilderVideosQuery
    }

    ... on Craft_PageBuilderMembershipSpecial {
      ...PageBuilderMembershipSpecialQuery
    }

    ... on Craft_PageBuilderHtml {
      ...PageBuilderHtmlQuery
    }

    ... on Craft_PageBuilderAppShowcase {
      ...PageBuilderAppShowcaseQuery
    }
  }
`;
